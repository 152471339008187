import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import { PageTitle } from "../PageTitle";

const Page = ({ page, pageName, title, parameters, children }) => {
  const currentPageIndex = parameters.menuItems.findIndex(
    (item) => item.name === pageName
  );

  const nextPage = parameters.menuItems[currentPageIndex + 1];
  const prevPage = parameters.menuItems[currentPageIndex - 1];

  return (
    <React.Fragment>
      <Header
        name={parameters.name}
        page={page}
        pageName={pageName}
        menuItems={parameters.menuItems}
        logo={parameters.logo}
      />
      {title && (
        <PageTitle
          title={title}
          name={parameters.name}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      )}
      <main className={`Page Page-${parameters.name}`}>
        <div className={`Page_main ${page}`}>{children}</div>
      </main>
      <Footer name={parameters.name} />
    </React.Fragment>
  );
};

export default Page;
