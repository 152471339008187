import React from "react";

const Footer = ({ name }) => {
  return (
    <footer className={`Footer Footer-${name}`}>
      <div className="Footer__block">
        <span className="Footer__block_link">
          <a
            href="https://www.diwservice.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.diwservice.com
          </a>
        </span>
        <span className="Footer__block_link">
          <a
            href="mailto:contact@diwservice.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@diwservice.com
          </a>
        </span>
        <span className="Footer__block_link">
          тел:&nbsp;
          <a href="tel:0443337113" target="_blank" rel="noopener noreferrer">
            <strong>(044) 333 7113</strong>
          </a>
          &nbsp; (Пн-Пт 9:00-18:00)
        </span>
      </div>
    </footer>
  );
};

export default Footer;
