import React from "react";
import { Link } from "gatsby";

import Arrow from "../../images/icons/double-arrow-left.svg";

const PageTitle = ({ title, name, nextPage, prevPage }) => (
  <section className={`PageTitle PageTitle-${name}`}>
    <div className="PageTitle_block">
      <div className="PageTitle_link PageTitle_link-left">
        {prevPage && (
          <Link to={prevPage.location} className="PageTitle_link_text">
            <Arrow className="PageTitle_link_arrow-left" />
            {prevPage.name}
          </Link>
        )}
      </div>

      <h3 className="PageTitle_title">{title}</h3>

      <div className="PageTitle_link PageTitle_link-right">
        {nextPage && (
          <Link to={nextPage.location} className="PageTitle_link_text">
            {nextPage.name}
            <Arrow className="PageTitle_link_arrow-right" />
          </Link>
        )}
      </div>
    </div>
  </section>
);

export { PageTitle };
