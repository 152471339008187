import React from "react";
import { Link } from "gatsby";
import { slide as Menu } from "react-burger-menu";

import House from "../../images/icons/house.svg";

const Header = ({ logo, name, pageName, menuItems }) => {
  const isMainPageGroup = name === "main" || name === "others";

  const menu = (
    <menu className="Header__nav_menu">
      {menuItems.map((item) => (
        <li
          className={`menu-item${
            item.name === pageName ? " menu-item-active" : ""
          }`}
          key={item.name}
        >
          <Link to={item.location} className="link">
            {item.name}
          </Link>
        </li>
      ))}
    </menu>
  );

  return (
    <header className={`Header Header-${name}`}>
      <Menu>{menu}</Menu>
      <nav className="Header__nav">
        <div className="Header__nav_logo">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          {!isMainPageGroup && (
            <div className="menu-item">
              <Link to="/" className="link">
                <House className="link-house" />
                Головна
              </Link>
            </div>
          )}
        </div>
        {menu}
      </nav>
    </header>
  );
};

export default Header;
