import Logo from "../images/logo.png";
import LogoHouseholders from "../images/DIWService_logo-Householders.png";
import LogoAdvertisers from "../images/DIWService_logo-Advertisers.png";

const main = {
  name: "main",
  menuItems: [
    {
      name: "Головна",
      location: "/",
    },
    {
      name: "Приклади",
      location: "/examples",
    },
    {
      name: "Контакти",
      location: "/contacts",
    },
  ],
  logo: Logo,
};

const homeowners = {
  name: "homeowners",
  menuItems: [
    {
      name: "Рішення",
      location: "/homeowners/solutions",
    },
    {
      name: "Деталі",
      location: "/homeowners/details",
    },
    {
      name: "Переваги",
      location: "/homeowners/benefits",
    },
    {
      name: "Відгуки",
      location: "/homeowners/reviews",
    },
    {
      name: "Контакти",
      location: "/homeowners/contacts",
    },
  ],
  logo: LogoHouseholders,
};

const advertisers = {
  name: "advertisers",
  menuItems: [
    {
      name: "Рішення",
      location: "/adv/solutions",
    },
    {
      name: "Переваги",
      location: "/adv/benefits",
    },
    {
      name: "Пропозиції",
      location: "/adv/suggestions",
    },
    {
      name: "Контакти",
      location: "/adv/contacts",
    },
  ],
  logo: LogoAdvertisers,
};

const others = {
  name: "others",
  menuItems: [],
  logo: Logo,
};

export { main, homeowners, advertisers, others };
